var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_c('p',{staticClass:"header__text"},[_vm._v("Выбрано "+_vm._s(_vm.count))]),_c('div',{staticClass:"header__buttons"},[_c('zem-link',{on:{"click":_vm.changeRightSidebar}},[_c('zem-icon',{attrs:{"name":"close","size":"18"}})],1)],1)]),(_vm.showClosedAll.length === this.fields.length && _vm.$route.name === 'exchange')?_c('div',{staticClass:"completed-orders__container mb-3 mt-3"},[_c('div',{staticClass:"completed-orders__title-block"},[_vm._v("Реализованы")]),_c('div',{staticClass:"completed-orders__data-block"},[_c('zem-button',{on:{"click":_vm.actionClosedOrder}},[_c('zem-icon',{staticClass:"cursor-pointer mr-2",attrs:{"name":"check","size":"12"}}),_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")],1),_c('zem-button',{staticClass:"ml-1",attrs:{"color":"danger"},on:{"click":_vm.actionClosedNegativeOrder}},[_c('zem-icon',{staticClass:"cursor-pointer mr-2",attrs:{"name":"close-o","size":"14"}}),_vm._v(" Нет ")],1)],1)]):_vm._e(),_vm._l((_vm.fields),function(item){return [_c('zem-collapse',{attrs:{"badge-error":_vm.handlerLogic(item),"title":item.title}},[_c('div',{staticClass:"zem-collapse-table rows"},_vm._l((item.data),function(field,f_idx){return _c('div',{key:f_idx,staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" "+_vm._s(field.name)+" ")]),(
              _vm.$store.state.user.role !== 'admin' &&
              item['created_by'] !== _vm.$store.state.user.profile.id &&
              field.hideField
            )?_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" Скрыто ")]):_c('div',{staticClass:"zem-collapse-table__column text"},[_vm._v(" "+_vm._s(field.name === 'Телефон' ? _vm.formattedPhone(field.value) : field.value)+" ")])])}),0)])]}),_c('ZemModal',{attrs:{"loading-success":_vm.loadingClosedOrder,"show":_vm.showClosedOrderModal,"title":_vm.statusClosedOrder === '1'
        ? 'Вы действительно хотите завершить заявку как успешную?'
        : `Вы действительно хотите завершить ${_vm.countOrders(
            _vm.fields.length
          )} как <b style='color: #ff4d4f;'>закрытые и нереализованные</b>?`,"title-cancel":_vm.$t('Cancel') || '',"color-cancel":"grey","title-success":"Завершить"},on:{"on-change":function($event){_vm.showClosedOrderModal = !_vm.showClosedOrderModal},"on-success":_vm.handlerClosedOrder,"on-cancel":function($event){_vm.showClosedOrderModal = false}}},[(_vm.statusClosedOrder === '1')?_c('p',{staticClass:"mt-3 text-large-1"},[_vm._v(" "+_vm._s(_vm.currentDeleteOrder + 1)+" заявка из "+_vm._s(_vm.fields.length)+" ")]):_vm._e(),(_vm.statusClosedOrder === '1')?_c('ZemCollapse',{staticClass:"mt-2",attrs:{"title":"Информация по заявке","title-not-bold":""}},[_c('div',{staticClass:"zem-collapse-table"},_vm._l((_vm.field),function(item,f_idx){return _c('div',{key:f_idx,staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"zem-collapse-table__column text"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0)]):_vm._e(),(_vm.statusClosedOrder === '1')?_c('ZemInput',{staticClass:"mt-1",attrs:{"integer":"","title":"Факт. бюджет","type":"text"},model:{value:(_vm.currentBudget),callback:function ($$v) {_vm.currentBudget=$$v},expression:"currentBudget"}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }