import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'
import OtherService from '@/services/other.service'
import {eventBus} from '@/main'

class ExchangeService {
  getAllOrders() {
    store.commit('other/onChangeLoadingItems', true)
    const {paginationPage, orderBy, sortedBy, filter, search, selectedItemsForDelete, isArchive} = store.state.exchange

    let newParams = {
      page: paginationPage,
      include: 'status,partner_status,region,winnerBy,organization,createdBy,organization_winner',
      searchJoin: 'and',
    }

    if (orderBy !== null) newParams['orderBy'] = orderBy
    if (sortedBy !== null) newParams['sortedBy'] = sortedBy
    if (filter !== null) newParams['search'] = filter.main
    if (search !== '') newParams['find'] = search

    if (isArchive) newParams['only-trashed'] = `1`

    return $api
      .get('partner/orders', {
        params: newParams,
        headers: authHeader(),
      })
      .then(r => {
        store.commit('exchange/getAllExchange', r.data)
        eventBus.$emit('update-table-settings', r.data.meta.custom.settings.value.data)
        if (selectedItemsForDelete.length !== 0) store.commit('exchange/itemsForDelete', [])
        store.commit('other/onChangeLoadingItems', false)
        errorHandler(r)
      })
  }

  getPartnerOrder(id) {
    return $api.get(`partner/orders/${id}`, {
      params: {
        include:
          'city,createdBy,region,status,country,commission,winnerBy,organization,partner_status,organization_winner',
      },
      headers: authHeader(),
    })
  }

  getSelectedOrders() {
    const {selectedItemsForDelete} = store.state.exchange
    if (OtherService.handlerOptions(selectedItemsForDelete, 'exchange/getSelectedExchange').search !== undefined) {
      return $api
        .get('partner/orders', {
          params: OtherService.handlerOptions(selectedItemsForDelete, 'exchange/getSelectedExchange'),
          headers: authHeader(),
        })
        .then(r => {
          store.commit('exchange/getSelectedExchange', r.data)
          eventBus.$emit('update-table-settings', r.data.meta.custom.settings.value.data)
        })
        .catch(e => {
          store.commit('exchange/getSelectedExchange', {data: []})
          errorHandler(e)
        })
    }
  }

  getCheckedOrders() {
    return $api.get('partner/orders', {
      params: OtherService.handlerOptions(store.state.exchange.selectedItemsForDelete, 'exchange/getSelectedExchange'),
      headers: authHeader(),
    })
  }

  checkStatusOrder(id) {
    return $api.get('partner/orders/' + id, {
      params: {
        include: 'status',
      },
      headers: authHeader(),
    })
  }

  createOrder(data) {
    return $api.post('partner/orders', data, {
      headers: authHeader(),
    })
  }

  deleteOrders() {
    return $api.delete('partner/orders', {
      headers: authHeader(),
      data: {
        ids: store.state.exchange.selectedItemsForDelete,
      },
    })
  }

  deleteOrder(id) {
    return $api.delete('partner/orders', {
      headers: authHeader(),
      data: {
        ids: [id],
      },
    })
  }

  updateOrder(id, data) {
    return $api.patch('partner/orders/' + id, data, {
      headers: authHeader(),
    })
  }

  getDaDataAddress(address) {
    return $api
      .post(
        'dadata/address-places',
        {},
        {
          params: {
            query: address,
          },
          headers: authHeader(),
        }
      )
      .then(response => {
        store.commit('exchange/getAddress', response.data)
      })
  }

  getFilterRegions() {
    return $api
      .get('regions?limit=*&to=list', {
        headers: authHeader(),
      })
      .then(response => {
        // const newArr = response.data.data.map(item => {
        //   return {
        //     id: item.id,
        //     title: item.name,
        //   }
        // })
        store.commit('other/getRegions', [
          {
            value: 0,
            title: 'Все регионы',
          },
          ...response.data.data,
        ])
        store.commit('other/getOnlyRegions', response.data.data)
      })
  }

  getFilterStatuses() {
    return $api
      .get('partner/order/statuses?to=list', {
        headers: authHeader(),
      })
      .then(response => {
        const newArr = response.data.data.map(item => {
          return {
            id: item.id,
            title: item.title,
          }
        })
        store.commit('exchange/getStatuses', [
          {
            id: 0,
            title: 'Все статусы на бирже',
          },
          ...newArr,
        ])
      })
  }

  getPartnerStatuses() {
    return $api
      .get('partner/order/partner-statuses?to=list', {
        headers: authHeader(),
      })
      .then(response => {
        const newArr = response.data.data.map(item => {
          return {
            id: item.id,
            title: item.title,
          }
        })
        store.commit('exchange/getPartnerStatuses', [
          {
            id: 0,
            title: 'Все статусы у партнера',
          },
          ...newArr,
        ])
      })
  }

  changeStatusActive(data) {
    return $api.patch(
      'partner/orders/status/NxOpZowo9GmjKqdR',
      {
        id: data,
      },
      {headers: authHeader()}
    )
  }

  changeStatusActiveOneOrder(id) {
    return $api.patch(
      'partner/orders/status/NxOpZowo9GmjKqdR',
      {
        id: id,
      },
      {headers: authHeader()}
    )
  }

  changeStatusArchive() {
    return $api.patch(
      'partner/orders/status/XbPW7awNkzl83LD6',
      {
        id: store.state.exchange.selectedItemsForDelete,
      },
      {headers: authHeader()}
    )
  }

  changePartnerStatusOrder(data, status) {
    return $api.patch(`${process.env.VUE_APP_URL}partner/orders/partner-status/${status}`, data, {
      headers: authHeader(),
    })
  }

  updateBudgetFinish(data, id) {
    return $api.patch(`${process.env.VUE_APP_URL}partner/orders/winner/${id}`, data, {headers: authHeader()})
  }

  checkSuccessPhone(phone) {
    let newParams = {
      searchJoin: 'and',
      search: 'order_status_id:aYOxlpzRMwrX3gD7,NxOpZowo9GmjKqdR',
      include: 'human,organization',
    }
    newParams['find'] = phone.replace('+', '')

    return $api.get('partner/orders', {
      params: newParams,
      headers: authHeader(),
    })
  }

  deleteItems(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api.delete('partner/orders', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.exchange.selectedItemsForDelete,
      },
    })
  }

  archiveItems() {
    return $api.post(
      'partner/orders/restore',
      {
        ids: store.state.exchange.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }
}

export default new ExchangeService()
