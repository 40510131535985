<template>
  <div class="container">
    <div class="header">
      <p class="header__text">Выбрано {{ count }}</p>
      <div class="header__buttons">
        <zem-link @click="changeRightSidebar">
          <zem-icon name="close" size="18" />
        </zem-link>
      </div>
    </div>
    <div
      v-if="showClosedAll.length === this.fields.length && $route.name === 'exchange'"
      class="completed-orders__container mb-3 mt-3"
    >
      <div class="completed-orders__title-block">Реализованы</div>
      <div class="completed-orders__data-block">
        <zem-button @click="actionClosedOrder">
          <zem-icon class="cursor-pointer mr-2" name="check" size="12" />
          {{ $t('Yes') }}
        </zem-button>
        <zem-button class="ml-1" color="danger" @click="actionClosedNegativeOrder">
          <zem-icon class="cursor-pointer mr-2" name="close-o" size="14" />
          Нет
        </zem-button>
      </div>
    </div>
    <template v-for="item in fields">
      <zem-collapse :badge-error="handlerLogic(item)" :title="item.title">
        <div class="zem-collapse-table rows">
          <div v-for="(field, f_idx) in item.data" :key="f_idx" class="zem-collapse-table__row">
            <div class="zem-collapse-table__column title">
              {{ field.name }}
            </div>
            <div
              v-if="
                $store.state.user.role !== 'admin' &&
                item['created_by'] !== $store.state.user.profile.id &&
                field.hideField
              "
              class="zem-collapse-table__column title"
            >
              Скрыто
            </div>
            <div v-else class="zem-collapse-table__column text">
              {{ field.name === 'Телефон' ? formattedPhone(field.value) : field.value }}
            </div>
          </div>
        </div>
      </zem-collapse>
    </template>

    <ZemModal
      :loading-success="loadingClosedOrder"
      :show="showClosedOrderModal"
      :title="
        statusClosedOrder === '1'
          ? 'Вы действительно хотите завершить заявку как успешную?'
          : `Вы действительно хотите завершить ${countOrders(
              fields.length
            )} как <b style='color: #ff4d4f;'>закрытые и нереализованные</b>?`
      "
      :title-cancel="$t('Cancel') || ''"
      color-cancel="grey"
      title-success="Завершить"
      @on-change="showClosedOrderModal = !showClosedOrderModal"
      @on-success="handlerClosedOrder"
      @on-cancel="showClosedOrderModal = false"
    >
      <p v-if="statusClosedOrder === '1'" class="mt-3 text-large-1">
        {{ currentDeleteOrder + 1 }} заявка из {{ fields.length }}
      </p>
      <ZemCollapse v-if="statusClosedOrder === '1'" class="mt-2" title="Информация по заявке" title-not-bold>
        <div class="zem-collapse-table">
          <div v-for="(item, f_idx) in field" :key="f_idx" class="zem-collapse-table__row">
            <div class="zem-collapse-table__column title">
              {{ item.name }}
            </div>
            <div class="zem-collapse-table__column text">
              {{ item.value }}
            </div>
          </div>
        </div>
      </ZemCollapse>
      <ZemInput
        v-if="statusClosedOrder === '1'"
        v-model="currentBudget"
        class="mt-1"
        integer
        title="Факт. бюджет"
        type="text"
      />
    </ZemModal>
  </div>
</template>

<script>
import ZemButton from '@/components/ui/ZemButton'
import ZemLink from '@/components/ui/ZemLink'
import CollapseCard from '@/components/CollapseCard'
import ExchangeService from '@/services/exchange.service'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemIcon from '@/components/ui/ZemIcon'
import messages from '@/helpers/messages'
import ZemModal from '@/components/ui/ZemModal'
import ZemInput from '@/components/ui/ZemInput'
import {errorHandler, formattedDate, formattedPhone, phoneTrim} from '@/assets/scripts/scripts'

export default {
  components: {
    ZemCollapse,
    ZemLink,
    ZemButton,
    CollapseCard,
    ZemIcon,
    ZemModal,
    ZemInput,
  },
  props: {
    fields: {
      type: Array,
      default: [],
    },
    count: {
      type: Number,
      default: 0,
    },
    takeAllOrders: {
      type: String,
      default: 'none',
    },
  },
  data() {
    return {
      loadingClosedOrder: false,
      showClosedOrderModal: false,
      statusClosedOrder: '',
      currentBudget: '',
      currentDeleteOrder: 0,
    }
  },
  computed: {
    showClosedAll() {
      let data
      if (this.$store.state.user.role === 'admin') {
        data = this.fields.filter(item => item.status === 'Активный').map(i => i.id)
      } else {
        data = this.fields
          .filter(item => item.winner_by === this.$store.state.user.profile.id && item.status === 'Активный')
          .map(i => i.id)
      }
      return data
    },
    arrCloseOrders() {
      let arr = []
      this.$store.state.exchange.selectedOrders.forEach(item => {
        arr.push({
          id: item.id,
          budget_finish: 0,
        })
      })
      return arr
    },
    field() {
      const obj = this.fields[this.currentDeleteOrder].full_data
      if (Object.keys(obj).length === 0) {
        return []
      } else {
        return [
          {
            name: 'Дата добавления',
            value: formattedDate(obj.created_at),
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Статус в кабинете',
            value: obj.status.data.title,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Статус у партнера',
            value: obj['partner_status'].data.title,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Бюджет',
            value: obj.budget_start,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Регион',
            value: obj.region.data.name,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Имя',
            value: obj.client_name,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Телефон',
            value: formattedPhone(obj.client_phone),
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Почта',
            value: obj.client_email,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Комментарий',
            value: obj.comment,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Адрес',
            value: obj.address,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Координаты',
            value: obj.location_lat !== '' ? obj.location_lat + ', ' + obj.location_long : '',
            type: 'text',
            new: false,
            read: false,
          },
        ]
      }
    },
  },
  methods: {
    formattedPhone,
    countOrders(count) {
      if ((count === 1 || String(count).at(-1) === '1') && count !== 11) {
        return `${count} заявка`
      } else if (((count > 1 && count < 5) || String(count).at(-1) === '2') && (count < 12 || count > 15)) {
        return `${count} заявки`
      } else if ((count > 4 && count < 21) || String(count).at(-1) === '2') {
        return `${count} заявок`
      }
    },
    handlerLogic(item) {
      if (this.$route.name === 'exchange') {
        if (this.$store.state.user.role !== 'admin') {
          if (item['created_by'] === this.$store.state.user.profile.id || item.status !== 'Новый') {
            return 'Нельзя взять в работу'
          } else {
            return ''
          }
        } else {
          if (item.status !== 'Новый') {
            return 'Нельзя взять в работу'
          } else {
            return ''
          }
        }
      } else {
        return ''
      }
    },
    changeRightSidebar() {
      this.$store.commit('peoples/itemsForDelete', [])
      this.$store.commit('companies/itemsForDelete', [])
      this.$store.commit('exchange/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
    takeAll() {
      const oldArr = this.$store.state.exchange.selectedOrders
      this.$store.commit('exchange/changeTakeOrdersInWorkPreloader', true)

      ExchangeService.getCheckedOrders().then(r => {
        let promise = new Promise((resolve, reject) => {
          oldArr.forEach((order, index) => {
            if (order.order_status_id !== r.data.data[index].order_status_id) {
              reject()
            }
            if (oldArr.length === index + 1) resolve()
          })
        })

        promise
          .then(() => {
            ExchangeService.changeStatusActive(this.$store.state.exchange.selectedItemsForDelete).then(() => {
              ExchangeService.getAllOrders()
              this.changeRightSidebar()
              this.$store.commit('exchange/itemsForDelete', [])
              this.$toast.success(messages.takeAllOrders)
              this.$store.commit('exchange/changeTakeOrdersInWorkPreloader', false)
            })
          })
          .catch(() => {
            this.$toast.error(messages.errorActiveOrders)
            this.$store.commit('exchange/getSelectedOrders', r.data)
          })
      })
    },
    takeSuccessAll() {
      const oldArr = this.$store.state.exchange.selectedOrders
      this.$store.commit('exchange/changeTakeAllOrdersInWorkPreloader', true)

      ExchangeService.getCheckedOrders().then(r => {
        let promise = new Promise((resolve, reject) => {
          oldArr.forEach((order, index) => {
            if (order['order_status_id'] !== r.data.data[index]['order_status_id']) {
              reject()
            }
            if (oldArr.length === index + 1) resolve()
          })
        })

        promise
          .then(() => {
            let data
            if (this.$store.state.user.role === 'admin') {
              data = this.fields.filter(item => item.status === 'Новый').map(i => i.id)
            } else {
              data = this.fields
                .filter(item => item['created_by'] !== this.$store.state.user.profile.id && item.status === 'Новый')
                .map(i => i.id)
            }

            ExchangeService.changeStatusActive(data).then(() => {
              ExchangeService.getAllOrders()
              this.changeRightSidebar()
              this.$store.commit('exchange/itemsForDelete', [])
              this.$toast.success(messages.takeSuccessAllOrders)
              this.$store.commit('exchange/changeTakeAllOrdersInWorkPreloader', false)
            })
          })
          .catch(() => {
            this.$toast.error(messages.errorActiveOrders)
            this.$store.commit('exchange/getSelectedOrders', r.data)
          })
      })
    },
    closeOrder() {
      this.loadingClosedOrder = true
      if (this.currentBudget !== '') {
        if (this.currentDeleteOrder + 1 !== this.fields.length) {
          ExchangeService.changePartnerStatusOrder(
            {
              orders: [
                {
                  id: this.arrCloseOrders[this.currentDeleteOrder].id,
                  budget_finish: this.currentBudget,
                },
              ],
            },
            'aYOxlpzRMwrX3gD7'
          )
            .then(() => {
              this.$toast.success(messages.closeOrders)
              this.loadingClosedOrder = false
              this.currentDeleteOrder++
              this.currentBudget = ''
            })
            .catch(e => {
              errorHandler(e)
            })
        } else {
          ExchangeService.changePartnerStatusOrder(
            {
              orders: [
                {
                  id: this.arrCloseOrders[this.currentDeleteOrder].id,
                  budget_finish: this.currentBudget,
                },
              ],
            },
            'aYOxlpzRMwrX3gD7'
          )
            .then(() => {
              this.$toast.success(messages.closeOrders)
              this.$store.commit('sidebars/changeRightSidebar', false)
              ExchangeService.getAllOrders()
              this.showClosedOrderModal = false
              this.loadingClosedOrder = false
              this.currentDeleteOrder = 1
            })
            .catch(e => {
              errorHandler(e)
            })
        }
      } else {
        this.loadingClosedOrder = false
        this.$toast.error(messages.errorCloseOrder)
      }
    },
    closeNegativeOrder() {
      this.loadingClosedOrder = true
      ExchangeService.changePartnerStatusOrder(
        {
          orders: this.arrCloseOrders,
        },
        '39n0Z12OZGKERJgW'
      )
        .then(() => {
          this.$toast.success(messages.closeNegativeOrders)
          this.$store.commit('sidebars/changeRightSidebar', false)
          ExchangeService.getAllOrders()
          this.showClosedOrderModal = false
          this.loadingClosedOrder = false
        })
        .catch(e => {
          errorHandler(e)
        })
    },
    handlerClosedOrder() {
      this.statusClosedOrder === '1' ? this.closeOrder() : this.closeNegativeOrder()
    },
    actionClosedOrder() {
      this.showClosedOrderModal = true
      this.statusClosedOrder = '1'
    },
    actionClosedNegativeOrder() {
      this.showClosedOrderModal = true
      this.statusClosedOrder = '2'
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 22px 18px;
}

.header {
  padding: 10px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #3e3e3e;
  }

  &__buttons {
    display: flex;

    button {
      margin-left: 10px;
    }
  }
}

.completed-orders {
  &__container {
    display: flex;
    align-items: center;
  }

  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: #9ba6b2;
    margin: 0 12px 0 0;
  }

  &__data-block {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 80px 20px 20px;
  }
}
</style>
